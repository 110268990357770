<template>
    <Footer class="kiu-footer">
        <template #prepend>
            <div class="kiu-footer__contact-info">
                <strong
                    >Sie haben Fragen oder einen Wunschtermin? Tel.
                    <a :href="'tel:' + supportPhoneNumber" class="kiu-footer__contact-phone">{{
                        supportPhoneNumber
                    }}</a>
                </strong>
            </div>
        </template>

        <template #append-column>
            <section class="col-sm footer-section">
                <header class="footer-heading">Zahlungsmöglichkeiten</header>

                <KIUFooterPaymentMethods />
            </section>
        </template>
    </Footer>
</template>

<script>
import Footer from '@/components/Footer.vue';
import KIUFooterPaymentMethods from '@/themes/kurzinurlaub/components/KIUFooterPaymentMethods.vue';

export default {
    name: 'KIUFooter',
    components: { KIUFooterPaymentMethods, Footer },
    computed: {
        supportPhoneNumber() {
            return this.$store.state.globalMeta.supportPhoneNumber;
        }
    }
};
</script>

<style scoped lang="scss">
.kiu-footer {
    margin-top: 1.25rem; /* Adding spacing for newsletter form above footer */
    background-color: var(--footer-bg-color);

    ::v-deep {
        #footer {
            padding: 1.25rem;
            margin-top: 0;

            section ul li {
                margin-bottom: 0.375rem;
            }
        }

        .footer-heading {
            border-bottom: 1px solid var(--border-color);
            padding-bottom: 0.5em;
            margin-bottom: 0.5rem !important;
        }
    }

    &__contact-info {
        margin-bottom: 1.25rem;
        font-size: clamp(1.125rem, 1.1214rem + 0.317vw, 1.375rem);
    }

    &__contact-phone {
        color: hsl(0, 0%, 100%);
    }
}
</style>
