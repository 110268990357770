<template>
    <div id="animod" :class="[priceModeCSS]">
        <KIUHeader ref="header" class="full-bleed" />
        <media-only desktop class="kiu-navigation-wrapper full-bleed">
            <div class="container">
                <Navigation v-if="showNavigation" />
            </div>
        </media-only>

        <main id="main">
            <nuxt />
        </main>

        <div v-if="showNewsletter" class="container">
            <Newsletter id="newsletter-box" class="mt-5" ref="newsletter" />
        </div>

        <KIUFooter class="full-bleed" />

        <FloatingBottom />
    </div>
</template>

<script>
import FloatingBottom from '@/components/layout/FloatingBottom.vue';
import priceModeMixin from '@/mixins/priceModesGlobal';
import authorizedLayout from '@/mixins/authorizedLayout';
import translatableLayout from '@/mixins/translatableLayout';
import themeableLayout from '@/mixins/themeableLayout';
import { getFaviconLinks } from '@/mixins/getHead';
import Navigation from '@/components/navigation/Navigation.vue';
import KIUHeader from '@/themes/kurzinurlaub/components/KIUHeader.vue';
import Footer from '@/components/Footer.vue';
import CheckoutFooter from '@/components/CheckoutFooter.vue';
import KIUFooter from '@/themes/kurzinurlaub/components/KIUFooter.vue';
import Newsletter from "@/components/newsletter/Newsletter.vue";

export default {
    name: 'KIUCombinedLayout',

    mixins: [priceModeMixin, authorizedLayout, translatableLayout, themeableLayout],

    components: {Newsletter, KIUFooter, CheckoutFooter, Footer, KIUHeader, Navigation, FloatingBottom },

    head() {
        return {
            link: [
                ...getFaviconLinks(
                    '/themes/kurzinurlaub/kiu-favicon.ico',
                    '/themes/kurzinurlaub/kiu-favicon.svg',
                    '/themes/kurzinurlaub/kiu-apple-touch-icon.png'
                )
            ]
        };
    }
};
</script>

<style lang="scss">
body:has(#animod) {
    // full-bleed fix
    overflow-x: hidden; // TODO: TEST in variaous scenarios
}

.full-bleed {
    // commented out section: cuts absolute dropdowns
    /*width: 100dvw;
	left: 50%;
	transform: translateX(-50%);
	position: relative;*/
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}

.kiu-navigation-wrapper {
    background-color: var(--secondary);
}
</style>
