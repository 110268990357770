<template>
    <div class="snackbar">
        <div class="snackbar__message" v-html="message"></div>
        <button type="button" class="snackbar__close btn-icon" @click="$emit('close')">
            <IconSvg filepath="close.svg" />
        </button>
    </div>
</template>

<script>
export default {
    name: 'Snackbar',
    data() {
        return {};
    },
    props: {
        message: String
    },
    computed: {}
};
</script>

<style lang="scss" scoped>
.snackbar {
    box-shadow: 0 3px 5px -1px hsl(0 0% 0% / 20%), 0 6px 10px 0 hsl(0 0% 0% / 14%), 0 1px 18px 0 hsl(0 0% 0% / 12%);
    background-color: #333;
    border-radius: 4px;
    align-items: center;
    border-color: currentColor;
    display: flex;
    justify-content: space-between;
    margin: 0;
    margin-bottom: 10px;
    max-width: 300px;
    min-width: 200px;
    padding: 15px;
    pointer-events: auto;
    position: relative;
    transition-duration: 0.15s;
    transition-property: opacity, transform;
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);

    &__message ::v-deep {
        a {
            color: var(--white);
            text-decoration: underline;
        }
    }
}

.snackbar__close {
    margin-left: 10px;
}

.snackbar,
.snackbar__close {
    color: hsl(0, 0%, 100%);
}
</style>
