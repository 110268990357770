<template>
    <ul class="navbar-nav mr-auto" ref="navItems">
        <li
            v-for="(entry, index) in entries"
            :key="'headerLi' + index"
            class="nav-item dropdown"
            :class="{ show: activeItem === entry.text }"
        >
            <!-- top level toggle button -->
            <a
                v-if="!entry.link || entry.children.length || entry[htmlProperty]"
                href="#"
                :id="'navbarDropdown' + index"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                :aria-expanded="activeItem === entry.text"
                :class="toggleClassName"
                @click.prevent.stop="setActiveItem(entry.text)"
            >
                {{ entry.text }}
            </a>
            <!-- top level link -->
            <Link v-else :link="entry.link || {}" :class="toggleClassName" @click="navClick" :mask="entry.shouldMask">
                {{ entry.text }}
            </Link>

            <!-- 2 level dropdown -->
            <div
                v-if="entry.children.length || entry[htmlProperty]"
                class="dropdown-menu"
                :aria-labelledby="'navbarDropdown' + index"
                :class="{ show: activeItem === entry.text }"
            >
                <!--
                    !important. Wrapped in a template tag, since nuxt doesn't render v-if v-else conditioned
                    elements correctly on a server side
                -->
                <!-- 2 level html, has priority over navigation model data-->
                <template v-if="entry[htmlProperty]">
                    <NavigationItemHtml :html="entry[htmlProperty]" />
                </template>

                <!-- 2 level dropdown model data -->
                <NavigationDropdown v-else :items="entry.children" />
            </div>
        </li>
    </ul>
</template>

<script>
import NavigationEntry from '@/models/pre-ts/navigation/NavigationEntry';
import NavigationDropdown from '@/components/navigation/NavigationDropdown';
import NavigationItemHtml from '@/components/navigation/NavigationItemHtml';

export default {
    name: 'NavigationItems',
    components: { NavigationItemHtml, NavigationDropdown },
    data() {
        return {
            activeItem: null,
            toggleClassName: 'nav-link'
        };
    },
    props: {
        htmlProperty: {
            type: String,
            default: 'menuHtmlCode'
        },
        entries: {
            type: [NavigationEntry, Array],
            required: true
        }
    },
    methods: {
        setActiveItem(item) {
            if (!this.activeItem) {
                this.activeItem = item;
            } else {
                this.activeItem = item === this.activeItem ? null : item;
            }
        },
        clearMenus(event) {
            if (!this.activeItem) {
                return;
            }

            const rightMouseButton = 3;
            const tabKeyCode = 9;
            if (event && (event.which === rightMouseButton || (event.type === 'keyup' && event.which !== tabKeyCode))) {
                return;
            }

            this.activeItem = null;
        },
        navClick() {
            this.$emit('navclick');
        },
        findCurrentNav() {
            const activeLink = this.$refs.navItems.querySelector('.nuxt-link-exact-active');
            if (activeLink) {
                let activeItem;
                try {
                    activeItem = activeLink.closest('.dropdown-menu').previousElementSibling;
                } catch (e) {
                    activeItem = activeLink;
                }

                this.activeItem = activeItem.textContent.trim();
            }
        }
    },
    mounted() {
        /**
         * Removed same handler for the touchend event since it prevents touch devices (e.g. iPad)
         * from clicking navigation items in landscape layout
         */
        document.addEventListener('click', this.clearMenus);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.clearMenus);
    }
};
</script>

<style lang="scss">
@use '@/styles/variables';
@use '@/styles/bootstrap/variables' as bsvars;
@use '@/styles/mixins';

.nav-item {
    @include mixins.desktop-only {
        &:not(:first-child)::before {
            border-left: 1px solid var(--border-color);
            content: '';
            position: absolute;
            width: 1px;
            height: 22px;
            left: 0;
            top: 50%;
            margin-top: -11px;
            opacity: 1;
        }
    }

    .nav-link {
        color: var(--navigation-top-lvl-color);
        padding-left: 12px;
        padding-right: 12px;
        font-size: 14px;
        position: relative;

        &::after {
            border-bottom: 2px solid var(--primary);
        }

        &[aria-expanded]::before {
            border-top: 7px solid var(--primary);
        }

        &::after {
            display: block;
            content: '';
            transform: scaleX(0);
            transition: transform 250ms ease-in-out;
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 4;
        }

        &:hover,
        &[aria-expanded],
        &.nuxt-link-exact-active {
            background-color: var(--navigation-top-lvl-bg-hover);
            color: var(--navigation-top-lvl-color-hover);

            &:not(.nuxt-link-exact-active)::after {
                transform: scaleX(1);
            }
        }

        &[aria-expanded]::before {
            content: ' ';
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -7px;
            z-index: bsvars.$zindex-dropdown + 1;
        }
    }

    .dropdown-menu {
        border-radius: 0;
        margin-top: 0;
    }
}
</style>
