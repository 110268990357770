<template>
    <transition name="slide-fade" :duration="duration" appear @after-enter="enterEnd">
        <div
            v-if="show"
            @click="close"
            @touchstart="swipeStart"
            @touchmove.passive="touchmove"
            @touchend="swipeEnd"
            class="dialog-swipe modal"
            tabindex="0"
            ref="mask"
            :class="{
                'modal--left': isLeft,
                'modal--bottom': isBottom,
                'modal--fullscreen': fullscreen,
                'modal--no-mask': noMask
            }"
        >
            <div class="modal__body" ref="dialog" :class="{ 'p-0': noGutters }">
                <slot></slot>
            </div>
        </div>
    </transition>
</template>

<script>
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export default {
    name: 'DialogSwipe',
    data() {
        return {
            startClientX: null,
            startClientY: null,
            initialMaskOpacity: null,
            swipeMaskOpacity: null,
            directions: ['left', 'bottom'],
            resultingOpacity: 0,
            elemYScroll: 0 //accumulates Y scroll on element before actual mask close hide down starts
        };
    },
    props: {
        show: Boolean,
        duration: {
            type: Number,
            default: 300
        },
        direction: {
            type: String,
            default: 'left'
        },
        closeThreshold: {
            type: [Number, String],
            default: 0.5
        },
        fullscreen: Boolean,
        disableSwipe: Boolean,
        noGutters: Boolean,
        noMask: Boolean
    },
    computed: {
        touchmove() {
            if (this.isLeft) {
                return this.swipeLeft;
            }
            return this.swipeBottom;
        },
        isLeft() {
            return this.direction === 'left';
        },
        isBottom() {
            return this.direction === 'bottom';
        }
    },
    methods: {
        close(e) {
            if (e.target === this.$refs.mask) {
                this.$emit('close', e);
            }
        },
        swipeStart(e) {
            if (this.disableSwipe) return;
            this.startClientX = e.changedTouches[0].clientX;
            this.startClientY = e.changedTouches[0].clientY;

            //if (e.target === this.$refs.navbar) {
            this.$refs.mask.classList.add('no-transition');
            this.$refs.dialog.classList.add('no-transition');
            //}

            if (!this.noMask) {
                try {
                    this.initialMaskOpacity = +getComputedStyle(this.$refs.mask)
                        .backgroundColor.split('(')[1]
                        .split(')')[0]
                        .split(',')[3]
                        .trim();
                } catch (e) {
                    this.initialMaskOpacity = 0.7;
                }
            }
        },
        swipeLeft(e) {
            if (this.disableSwipe) return;
            const offsetX = e.changedTouches[0].clientX - this.startClientX;
            const offsetY = e.changedTouches[0].clientY - this.startClientY;
            const dialog = this.$refs.dialog;

            //allowing only left swipe
            if (offsetX > 0) {
                return false;
            }

            //preventing both axis swipes simultaneously. Y stands for vertical scroll only, X - attempt to swipe away (left)
            /*if (Math.abs(offsetY) > Math.abs(offsetX)) {
                    return false;
                }*/

            dialog.style.transform = `translateX(${offsetX}px)`;
            this.resultingOpacity = Math.max(
                0,
                (this.initialMaskOpacity * (dialog.clientWidth + offsetX)) / dialog.clientWidth
            );

            if (!this.noMask) {
                this.$refs.mask.style.backgroundColor = `rgba(0, 0, 0, ${this.resultingOpacity.toFixed(2)})`;
            }
        },
        swipeBottom(e) {
            if (this.disableSwipe) return;
            let offsetY = e.changedTouches[0].clientY - this.startClientY;
            const dialog = this.$refs.dialog;

            //allowing only bottom swipe
            if (offsetY < 0) {
                return false;
            }

            if (dialog.scrollTop) {
                this.elemYScroll = offsetY; //accumulating false element scroll offset
                return false;
            } else {
                offsetY = offsetY - this.elemYScroll;
            }

            dialog.style.transform = `translateY(${offsetY}px)`;
            this.resultingOpacity = Math.max(
                0,
                (this.initialMaskOpacity * (dialog.clientHeight - offsetY)) / dialog.clientHeight
            );
            if (!this.noMask) {
                this.$refs.mask.style.backgroundColor = `rgba(0, 0, 0, ${this.resultingOpacity.toFixed(2)})`;
            }
        },
        swipeEnd(e) {
            if (this.disableSwipe) return;
            const clientX = e.changedTouches[0].clientX;
            const clientY = e.changedTouches[0].clientY;
            const offsetX = clientX - this.startClientX;
            const offsetY = clientY - this.startClientY;
            const { dialog, mask } = this.$refs;
            let dialogDimension, offset, closeThreshold, currentTransform;

            mask.classList.remove('no-transition');
            dialog.classList.remove('no-transition');

            if (this.isLeft) {
                dialogDimension = dialog.clientWidth;
                offset = offsetX;
                closeThreshold = dialog.clientWidth * this.closeThreshold;
                currentTransform = `translateX(${offsetX}px)`;

                //avoiding swipe to right
                if (offsetX >= 0) {
                    return false;
                }
            } else if (this.isBottom) {
                dialogDimension = dialog.clientHeight;
                offset = offsetY - this.elemYScroll; //reducing by false scroll on element, not on dialog itself
                this.elemYScroll = 0;
                closeThreshold = dialog.clientHeight * this.closeThreshold;
                currentTransform = `translateY(${offsetY}px)`;

                //avoiding swipe to top
                if (offsetY <= 0) {
                    return false;
                }
            }

            //avoiding micro swipes
            const absOffset = Math.abs(offset);
            if (absOffset <= 3) {
                return false;
            }

            if (absOffset < closeThreshold && absOffset < dialogDimension) {
                dialog.style.transform = '';
                mask.style.backgroundColor = '';
            } else {
                dialog.style.transform = currentTransform;
                mask.style.backgroundColor = `rgba(0, 0, 0, ${this.resultingOpacity.toFixed(2)})`;
                this.$emit('close', e);
            }
        },
        enterEnd() {
            /*
             * Fix for flex browser height with address bar ot toolbar
             */
            this.$emit('opened', this.$refs.dialog);
            disableBodyScroll(this.$refs.dialog);
        },

        afterLeave() {},
        deactivate() {
            enableBodyScroll(this.$refs.dialog);

            this.startClientX = null;
            this.startClientY = null;

            this.$refs.dialog.style.transform = '';
            //not using after-leave handler because it's not fired upon proceeding to the checkout
            document.dispatchEvent(new Event('dialogSwipe:afterClose'));
        }
    },
    /* beforeMount() {
        console.assert(
            this.directions.includes(this.direction),
            `The DialogSwipe's direction property can be ${this.directions.join(' or ')}`
        );
        document.dispatchEvent(new Event('dialogSwipe:beforeOpen'));
    }, */
    //if wrapped in a keep-alive
    activated() {
        //disableBodyScroll(this.$refs.dialog);
    },
    deactivated() {
        //this.deactivate();
    },
    //if not wrapped in a keep-alive
    /* mounted() {
        disableBodyScroll(this.$refs.dialog);
    }, */
    beforeDestroy() {
        //this.deactivate();
        enableBodyScroll(this.$refs.dialog);
    },
    watch: {
        show(value) {
            if (value) {
                document.dispatchEvent(new Event('dialogSwipe:beforeOpen'));
            } else {
                enableBodyScroll(this.$refs.dialog);

                this.startClientX = null;
                this.startClientY = null;

                //this.$refs.dialog.style.transform = '';
                //not using after-leave handler because it's not fired upon proceeding to the checkout
                document.dispatchEvent(new Event('dialogSwipe:afterClose'));
            }
        }
    }
};
</script>

<style scoped lang="scss">
@use '@/styles/variables';
@use 'sass:map';

.modal {
    $self: &;

    position: fixed;
    z-index: map.get(variables.$z-indices, 'modal');
    height: 100%; //100% instead of 100vh because vh isn't recalculated on mobile address bar/toolbar toggle
    top: 0;
    width: 100%;
    left: 0;
    background-color: hsla(0, 0%, 0%, 0.7);
    transition: all 0.2s ease;
    display: flex;

    &--no-mask {
        background-color: transparent;
        transition: none;
    }

    &__body {
        background-color: var(--white);
        box-shadow: 3px 0 5px 2px hsla(0, 0%, 0%, 0.1);
        overflow-y: auto;
        transition-property: transform, opacity;
        transition-duration: 200ms;
        transition-timing-function: ease-in-out;
        padding: variables.$grid-padding;
    }

    &--left {
        align-items: flex-start;

        #{$self}__body {
            height: 100%;
            padding: 0;
            //transform: translateX(0);
        }
    }

    &--bottom {
        #{$self}__body {
            width: 100vw;
            //transform: translateY(0);
            margin-top: auto;
            max-height: 80%;
        }
    }

    &--fullscreen {
        #{$self}__body {
            max-height: none;
            height: 100%;
        }
    }

    //!important in order to override js declared styles
    &.slide-fade-enter,
    &.slide-fade-leave-to {
        background-color: transparent !important;

        &#{$self}--left #{$self}__body {
            transform: translateX(-100%) !important;
            opacity: 0;
        }

        &#{$self}--bottom #{$self}__body {
            transform: translateY(100%) !important;
            opacity: 0;
        }
    }
}

.no-transition {
    transition: none !important;
}
</style>
