<template>
    <div class="customer-service" :class="{ inline }">
        <slot name="prepend"></slot>
        <slot name="header">
            <span class="service-text">{{ $t('Generic.customerService') }}{{ inline ? ' :' : '' }}</span>
        </slot>
        <span class="phone">{{ supportPhone }}</span>
        <span class="working-hours">{{ joinedOfficeHours }}</span>
    </div>
</template>

<script>
export default {
    name: 'CustomerService',
    props: {
        inline: Boolean
    },
    computed: {
        globalMeta() {
            return this.$store.state.globalMeta;
        },
        joinedOfficeHours() {
            if (!this.globalMeta.officeHours) {
                return '';
            }
            const [from, until] = [...this.globalMeta.officeHours];
            return `(${from} - ${until} ${this.$t('Generic.hour', null, 1)})`;
        },
        supportPhone() {
            /**
             * For ajax failure cases we need fallback static phone
             */
            return this.globalMeta.supportPhoneNumber || '0123 -456 78-90';
        }
    }
};
</script>

<style scoped lang="scss">
@use '@/styles/variables';
@use '@/styles/theming' as *;

.customer-service {
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 1.1em;

    &:not(.inline) {
        font-size: 20px;

        .working-hours,
        .service-text {
            font-size: 0.7em;
        }

        .phone {
            font-weight: bold;
        }
    }

    &.inline {
        flex-direction: row;
        justify-content: center;

        .service-text {
            margin-right: 0.25rem;
        }

        .working-hours {
            font-style: italic;
            @include themify {
                color: themed('$topbar-workinghours-color');
            }
            margin-left: 0.25rem;
            //empty block jumps prevention
            min-height: 1em;
            min-width: 75px;
            display: inline-block;
        }
    }
}
</style>
