<template>
    <header id="header" class="kiu-header">
        <div class="kiu-header__container container">
            <media-only mobile>
                <button
                    class="kiu-header__burger"
                    type="button"
                    aria-controls="navigation"
                    :aria-label="$t('Header.toggleNavigation')"
                    @click.stop="showNavigation = true"
                >
                    <IconSvg filepath="burger.svg" />
                </button>
            </media-only>

            <!-- keep alive to prevent from computing active on each menu open -->
            <DialogSwipe :show="showNavigation" @close="showNavigation = false" class="kiu-header__mobile-menu">
                <NavigationMobile @close="showNavigation = false" />
            </DialogSwipe>

            <Logo :src="platformLogoHref" class="kiu-header__main-logo" />

            <media-only mobile class="kiu-header__button-search-wrapper">
                <button type="button" class="kiu-header__button-search btn" @click="showSearch = !showSearch">
                    <icon-svg filepath="search/magnifier.svg" />
                </button>
            </media-only>

            <KIUSearchAutocomplete
                :autofocus="showSearch"
                class="kiu-header__autocomplete d-none d-lg-flex"
                :class="{ 'd-block': showSearch }"
            />

            <media-only desktop>
                <KIUCustomerService />
            </media-only>
        </div>
    </header>
</template>

<script>
import Logo from '@/components/Logo.vue';
import KIUSearchAutocomplete from '@/themes/kurzinurlaub/components/KIUSearchAutocomplete.vue';
import KIUCustomerService from '@/themes/kurzinurlaub/components/KIUCustomerService.vue';
import NavigationMobile from '@/components/navigation/NavigationMobile.vue';
import DialogSwipe from '@/components/mobile/DialogSwipe.vue';

export default {
    name: 'KIUHeader',
    components: { DialogSwipe, NavigationMobile, KIUCustomerService, KIUSearchAutocomplete, Logo },

    data() {
        return {
            query: '',
            showNavigation: false,
            showSearch: false
        };
    },

    computed: {
        platformLogoHref() {
            return (
                this.$store.state.globalMeta.platformLogoUrl || require('@/themes/kurzinurlaub/assets/logo/logo.png')
            );
        }
    },

    watch: {
        showSearch() {
            if (this.showSearch) {
                const autocompleteInput = document.querySelector('.kiu-header__autocomplete .autocomplete__input');
                if (autocompleteInput) {
                    setTimeout(() => {
                        autocompleteInput.focus();
                    }, 0);
                }
            }
        },

        $route() {
            this.showSearch = false;
        }
    }
};
</script>

<style scoped lang="scss">
@use '@/styles/mixins';

.kiu-header {
    z-index: 10; // in order to show autocomplete dropdown
    padding-block: 0.625rem;
    border-bottom: 1px solid var(--border-color);

    &__container {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 0.5rem;

        @include mixins.mobile-only {
            overflow: visible; // needed for autocomplete dropdown
        }
    }

    &__main-logo {
        height: auto;
        width: min(45vw, var(--logo-main-width-desktop));
    }

    &__autocomplete {
        max-width: 38%;
        margin: auto;

        @include mixins.mobile-only {
            max-width: 96dvw;
            flex-basis: 100% !important;
            margin-top: 0.5rem;
            margin-bottom: 3px; //scrollbar prevention
        }
    }

    @include mixins.mobile-only {
        &__main-logo {
            margin: 0 auto;
        }
    }

    &__burger {
        background-color: transparent;
        border: none;
        font-size: 1.75rem;
        display: flex;
        color: var(--gray);
    }

    &__button-search-wrapper {
        flex: 0 0 40px;
        text-align: right;
    }

    &__button-search {
        color: var(--gray);
        font-size: clamp(1.375rem, 1.3697rem + 0.4754vw, 1.75rem);
        padding: 0;
        display: inline-flex;

        &:focus {
            box-shadow: none;
        }
    }
}
</style>
