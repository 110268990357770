<template>
    <div v-if="legalPostscriptum">
        <hr />
        <div class="row small">
            {{ legalPostscriptum }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'FooterLegalInfo',
    computed: {
        theme() {
            return this.$store.state.theme;
        },
        legalPostscriptum() {
            let value = this.$t('Generic.bottom.text');
            return value && value.trim() !== '' ? value : null;
        }
    }
};
</script>

<style scoped lang="scss">
@use '@/styles/variables';

.small {
    justify-content: center;
    color: var(--footer-link-color-darker);
}
</style>
