<template>
    <li>
        <div class="nav-mobile-menu__list-item" :class="{ 'nav-mobile-menu__list-item--active': item.activeUrl }">
            <div v-if="item.icon" class="nav-mobile-menu__icon nav-mobile-menu__item-icon">
                <IconSvg :filepath="`navigation/${item.icon}`" />
            </div>

            <div class="nav-mobile-menu__item-wrapper">
                <!-- v-html needed to support text like price toggle html -->
                <Link
                    v-if="item.link && !item.link.empty"
                    :link="item.link || {}"
                    class="nav-mobile-menu__item-link"
                    @click="navClick"
                    v-html="item.text"
                    :mask="item.shouldMask"
                />
                <div v-else class="nav-mobile-menu__item-text" @click="hasSubItems && openSubMenu()">
                    {{ item.text }}
                </div>
            </div>

            <button
                v-if="hasSubItems"
                class="nav-mobile-menu__toggle-icon nav-mobile-menu__icon btn-icon"
                @click="openSubMenu"
            >
                <IconSvg filepath="arrow-right.svg" />
            </button>
        </div>
    </li>
</template>

<script>
export default {
    name: 'NavigationMenuMobileListItem',
    data() {
        return {};
    },

    props: {
        item: {
            type: Object,
            required: true
        }
    },

    computed: {
        hasSubItems() {
            return (this.item.children && this.item.children.length) || this.item.mobileMenuHtmlCode;
        }
    },

    methods: {
        openSubMenu() {
            this.$emit('openSubMenu');
        },
        navClick() {
            this.$emit('navClick');
        }
    }
};
</script>

<style scoped lang="scss">
$padding: 10px;

.nav-mobile-menu {
    &__list-item {
    }

    &__item-wrapper {
        flex: 1 1 auto;
        overflow: hidden;
        padding: 0 $padding;
    }

    &__item-link,
    &__item-text {
        line-height: 1.2;
        display: block;
        color: var(--mobile-menu-link-color);
    }

    &__item-icon {
        margin-left: $padding;
        flex: 0 0 40px;
        display: flex;
        font-size: 30px;

        .icon-svg {
            margin: auto;
        }
    }

    &__toggle-icon {
        margin-left: auto;
        padding: 7px;
    }

    &__item-icon {
        color: var(--mobile-menu-icon-color);
    }
}
</style>
