/**
 * @class
 */
export class NavItemWithMasking {
    /**
     * @constructor
     * @param {Object} navItem
     * @param {Boolean} shouldMask
     */
    constructor(navItem, shouldMask) {
        Object.assign(this, navItem);
        this.shouldMask = shouldMask;
    }
}

export default {
    computed: {
        footerItemsWithMasking() {
            const isStartPage = this.isStartPage;
            return (this.$store.state.globalMeta.footerLinks || []).map((footerItem, sectionIndex) => {
                // Top-Reiseziele footer section should not be masked. Section title is dynamic, so checking index
                return {
                    ...footerItem,
                    links: (footerItem.links || []).map(
                        child => new NavItemWithMasking(child, !isStartPage && sectionIndex !== 0)
                    )
                };
            });
        },

        isStartPage() {
            return this.$route.name === 'index';
        },

        navigationItemsWithMasking() {
            return this.$store.state.globalMeta.navigationEntries.map(
                headerItem =>
                    new NavItemWithMasking({
                        ...headerItem,
                        children: headerItem.children.map(
                            child =>
                                new NavItemWithMasking({
                                    ...child,
                                    children: (child.children || []).map(
                                        subChild =>
                                            new NavItemWithMasking(
                                                {
                                                    ...subChild,
                                                    children: (subChild.children || []).map(
                                                        subSubChild =>
                                                            new NavItemWithMasking(
                                                                {
                                                                    ...subSubChild,
                                                                    children: (subSubChild.children || []).map(
                                                                        deepestChild =>
                                                                            new NavItemWithMasking(
                                                                                deepestChild,
                                                                                !this.isStartPage
                                                                            )
                                                                    )
                                                                },
                                                                !this.isStartPage
                                                            )
                                                    )
                                                },
                                                !this.isStartPage
                                            )
                                    )
                                })
                        )
                    })
            );
        }
    }
};
