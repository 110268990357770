<template>
    <div class="nav-mobile-menu">
        <div v-if="parent" class="nav-mobile-menu__header">
            <button @click="setActive(null)" class="nav-mobile-menu__header-icon nav-mobile-menu__icon btn-icon">
                <IconSvg filepath="arrow-right.svg" />
            </button>

            <span>
                {{ parent.text }}
            </span>
        </div>

        <template v-if="html">
            <NavigationItemHtml :html="html" />
        </template>

        <NavigationMenuMobileList :items="items" @openSubMenu="openSubMenu" @navClick="navClick" />

        <transition name="slide">
            <NavigationMenuMobile
                v-if="showSubMenu"
                :html="activeComputed.mobileMenuHtmlCode"
                :items="activeComputed.children"
                :parent="activeComputed"
                @changeActive="changeActiveChild"
                @navclick="$emit('navclick')"
            />
        </transition>
    </div>
</template>

<script>
import NavigationEntry from '@/models/pre-ts/navigation/NavigationEntry';
import NavigationItemHtml from '@/components/navigation/NavigationItemHtml';
import NavigationMenuMobileList from '@/components/navigation/NavigationMenuMobileList';

const NavigationMenuMobile = {
    name: 'NavigationMenuMobile',

    components: {
        NavigationMenuMobileList,
        NavigationItemHtml,
        NavigationMenuMobile: this
    },

    data() {
        return {
            activeItem: null
        };
    },

    props: {
        items: {
            type: Array,
            required: true
        },
        html: {
            type: String
        },
        parent: {
            type: [NavigationEntry, Object]
        },
        activeExternal: Object
    },

    computed: {
        showSubMenu() {
            return (
                !!this.activeComputed &&
                (!!this.activeComputed.children.length || !!this.activeComputed.mobileMenuHtmlCode)
            );
        },

        activeComputed: {
            get() {
                return this.activeExternal || this.activeItem;
            },
            set(active) {
                this.$emit('update:activeExternal', active);
                this.activeItem = active;
            }
        }
    },

    methods: {
        openSubMenu(item) {
            this.activeComputed = item;
        },

        setActive(item) {
            this.$emit('changeActive', item);
        },

        navClick(item) {
            const active = this.findActiveUrl();
            if (active) {
                this.$set(active, 'activeUrl', false);
            }

            this.$set(item, 'activeUrl', true);
            this.activeComputed = item;

            // nextTick to
            this.$nextTick(() => {
                this.$emit('navclick');
            });

            return true;
        },

        changeActiveChild(item) {
            if (!item) {
                this.activeComputed = null;
            }
        },

        findActiveUrl() {
            return this.items.find(i => i.activeUrl);
        }
    },

    created() {
        this.activeItem = this.findActiveUrl();
    }
};

export default NavigationMenuMobile;
</script>

<style lang="scss">
@use '@/styles/variables';

.nav-mobile-menu {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 1px;
    transform: translateX(0);
    transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    overflow: hidden;
    overflow-y: auto;

    &__header {
        line-height: 1;
    }

    &__header,
    &__list-item {
        display: flex;
        align-items: center;
        min-height: 40px;
    }

    &__icon {
        font-size: 20px;
    }

    &__header-icon {
        font-size: 30px;

        .icon-svg {
            transform: rotate(180deg);
        }
    }

    &,
    &__list {
        &__header {
            border-bottom: 1px solid var(--border-color);
        }

        &__toggle-icon {
            border-left: 1px solid var(--border-color-opaque);
        }

        &--footer {
            border-top: 1px solid var(--border-color-opaque);
        }
    }

    &,
    &__list {
        background-color: var(--mobile-menu-bg);
        color: var(--mobile-menu-color);

        &__header {
            background-color: color-mix(in srgb, var(--mobile-menu-bg), white 40%);
        }

        &__list-item--active {
            background-color: color-mix(in srgb, var(--mobile-menu-bg), white 20%);
        }
    }

    &,
    &__list {
        &__toggle-icon,
        &__header-icon {
            color: var(--mobile-menu-color);
        }
    }

    .btn-icon {
        display: inline-flex;
    }

    &.slide-enter,
    &.slide-leave-to {
        transform: translateX(-100%);
    }
}
</style>
