<template>
    <nav id="navigation" ref="navbar" class="mobile-menu navbar navbar-expand-lg" role="navigation">
        <div class="navbar__menu-wrapper">
            <NavigationMenuMobile
                :items="headerItemsWithActiveSpecified"
                @navclick="closeNavbar"
                ref="navigationMenuMobile"
                :active-external.sync="activeFooter"
            />
        </div>

        <NavigationMenuMobileList
            :items="footerItemsWithActiveSpecified"
            @openSubMenu="footerNavClick"
            class="nav-mobile-menu__list--footer"
        />
    </nav>
</template>

<script>
import NavigationItems from '@/components/navigation/NavigationItems';
import DialogSwipe from '@/components/mobile/DialogSwipe';
import NavigationMenuMobile from '@/components/navigation/NavigationMenuMobile';
import getLink from '@/mixins/getLink';
import cloneDeep from '@/utils/cloneDeep';
import NavigationMenuMobileList from '@/components/navigation/NavigationMenuMobileList';
import navigationItemsMasking from '@/mixins/navigationItemsMasking';

export default {
    name: 'NavigationMobile',

    mixins: [getLink, navigationItemsMasking],

    components: {
        NavigationMenuMobileList,
        NavigationMenuMobile,
        NavigationItems,
        DialogSwipe
    },

    data() {
        return {
            activeFooter: null,
            foundActive: false
        };
    },

    props: {
        show: Boolean
    },

    computed: {
        navigationItems() {
            //need a clone to "mutate" navigation without committing the store mutation
            return cloneDeep(this.navigationItemsWithMasking);
        },

        footerItems() {
            return cloneDeep([
                ...this.footerItemsWithMasking.map(footerSection => {
                    return {
                        text: footerSection.title,
                        children: footerSection.links
                    };
                })
            ]);
        },

        footerItemsWithActiveSpecified() {
            return this.highlightActiveUrl(this.footerItems, true);
        },

        flattenedWithoutHeader() {
            return this.flattenWithoutHeader(this.navigationItems);
        },

        headerItemsWithActiveSpecified() {
            return this.highlightActiveUrl(this.flattenedWithoutHeader);
        }
    },

    methods: {
        closeNavbar() {
            this.$emit('close');
        },

        footerNavClick(footerItem) {
            // In order to keep footer menu static, $refs method call is the way to set active item from outside.
            // Having a prop instead adds complexity inside the NavigationMenuMobile

            if (this.activeFooter === footerItem) {
                return;
            }

            // Clearing the possible active deep menu, otherwise this deep menu will overlap the footer menu
            this.$refs.navigationMenuMobile.changeActiveChild(null);
            // $nextTick produces more UX distraction than timeout 100ms
            setTimeout(() => {
                // setting footer items as a submenu of topmost navigation
                //this.$refs.navigationMenuMobile.openSubMenu(footerItem);
                this.activeFooter = footerItem;
            }, 100);
        },

        highlightActiveUrl(items, deepestOnly) {
            /**
             * @function
             * recursive local function that search through the navigation hierarchy for active item via comparing its link to the current route
             * Required when loading page without navigating to it
             */
            const checkLink = (item, parent, grandparent, topLevel) => {
                const activeUrlKey = 'activeUrl';

                this.$set(item, activeUrlKey, false);

                const resolved = item.link && this.$router.resolve(this.getLink(item.link));

                if (resolved && resolved.href === this.$route.path) {
                    this.$set(item, activeUrlKey, true);
                    if (!deepestOnly) {
                        parent && this.$set(parent, activeUrlKey, true);
                        grandparent && this.$set(grandparent, activeUrlKey, true);
                        topLevel && this.$set(topLevel, activeUrlKey, true);
                    }
                    this.foundActive = true;
                }

                item.children &&
                    item.children.forEach(c => {
                        checkLink(c, item, parent, grandparent);
                    });
            };

            if (!this.foundActive) {
                items.forEach(i => checkLink(i));
            }
            return items;
        },

        flattenWithoutHeader(items) {
            return items.reduce((result, current) => {
                const cannotNavigateDown = !current.text && !current.link;

                if (cannotNavigateDown) {
                    result = result.concat(current.children);
                } else {
                    result.push(current);
                }

                current.children && (current.children = this.flattenWithoutHeader(current.children));

                return result;
            }, []);
        }
    },

    created() {
        this.activeFooter = this.footerItemsWithActiveSpecified.find(f => (f.children || []).find(fc => fc.activeUrl));
    },

    watch: {
        // Important. The navigation to the footer subroutes occurs after the activeFooter variables has set.
        // The activeFooter will be not synced with updates in footerItems after the router navigation (e.g. item.shouldMask)
        // This watcher reassigns the active footer after any updates to footerItems
        footerItems: {
            //deep: true,
            handler(items) {
                if (this.activeFooter) {
                    const newActiveFooter = items.find(i => i.text === this.activeFooter.text);
                    if (newActiveFooter) {
                        try {
                            this.activeFooter = {
                                ...newActiveFooter,
                                activeUrl: this.activeFooter.activeUrl,
                                children: newActiveFooter.children.map((c, i) => ({
                                    ...c,
                                    activeUrl: this.activeFooter.children[i].activeUrl
                                }))
                            };
                        } catch (e) {
                            console.log('was not able to find process the active footer mobile nav');
                        }
                    }
                }
            }
        }
    }
};
</script>

<style scoped lang="scss">
@use '@/styles/variables';

.navbar {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-wrap: nowrap;
    align-items: stretch;
    padding: 0;

    &__menu-wrapper {
        position: relative;
        flex: 1 0 auto;
    }
}

#navigation {
    width: variables.$mobile-navbar-width;
}
</style>
