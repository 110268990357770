import priceModes from '@/models/priceModes';

/**
 *  Designed to be used once, inside App.vue. It unloads App's code structure
 *  @mixin
 */
export default {
    data() {
        return {
            priceModes
        };
    },
    computed: {
        priceMode() {
            return this.$store.state.priceMode;
        },
        priceModeCSS() {
            return this.priceMode.cssClass;
        }
    },
    methods: {
        togglePriceMode() {
            const nextMode = this.priceModes.nextLoop(this.priceMode);
            this.$store.dispatch('togglePriceMode', nextMode);
            if (!nextMode.enabled) {
                this.togglePriceMode();
            }
        },
        appendGlobalCSS() {
            const cssText = [...this.priceModes].reduce((final, current, index, modes) => {
                const otherModesHideRules = modes.reduce((otherClasses, mode) => {
                    if (mode.cssClass !== current.cssClass) {
                        otherClasses += `
                            #animod.${current.cssClass} .${mode.cssClass} {
                                display: none;
                            }
                        `;
                    }
                    return otherClasses;
                }, '');

                final += otherModesHideRules;

                return final;
            }, '');

            const style = document.createElement('style');
            style.type = 'text/css';

            if (style.styleSheet) {
                style.styleSheet.cssText = cssText;
            } else {
                style.appendChild(document.createTextNode(cssText));
            }
            document.getElementsByTagName('head')[0].appendChild(style);
        }
    },
    created() {
        if (!this.priceMode.property) {
            this.togglePriceMode();
        }
    },
    beforeMount() {
        window.priceDisplayMode = {
            toggle: this.togglePriceMode
        };
        this.appendGlobalCSS();
    }
};
