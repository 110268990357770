<template>
    <div
        class="navigation-dropdown"
        :class="{
            'navigation-dropdown__deepest-list': isDeepestList(items),
            'navigation-dropdown__two-levels-list': maxTwoLevels
        }"
    >
        <div
            v-for="(secondLevel, secondLevelIndex) in items"
            :key="'item' + secondLevelIndex"
            class="navigation-dropdown__menu-lvl-2"
        >
            <div class="navigation-dropdown__link-wrapper">
                <Link
                    v-if="secondLevel.link && !secondLevel.link.empty"
                    :link="secondLevel.link || {}"
                    class="navigation-dropdown__second-level-nav navigation-dropdown__nav"
                    :mask="secondLevel.shouldMask"
                >
                    {{ secondLevel.text }}
                </Link>
                <div v-else class="navigation-dropdown__second-level-nav navigation-dropdown__nav">
                    {{ secondLevel.text }}
                </div>
            </div>

            <div
                v-if="secondLevel.children && secondLevel.children.length"
                class="navigation-dropdown__horizontal navigation-dropdown__menu"
                :class="{ 'navigation-dropdown__deepest-list': isDeepestList(secondLevel.children) }"
            >
                <div
                    v-for="(thirdLevel, thirdLevelIndex) in secondLevel.children"
                    :key="'thirdLevelHeader' + thirdLevelIndex"
                    class="navigation-dropdown__vertical navigation-dropdown__menu-lvl-3"
                >
                    <!-- Need to compare to index instead of text for setting active: text might be null =< will activate by default -->
                    <div class="navigation-dropdown__link-wrapper">
                        <Link
                            v-if="thirdLevel.link && !thirdLevel.link.empty"
                            :link="thirdLevel.link || {}"
                            class="navigation-dropdown__third-level-nav navigation-dropdown__nav"
                            :mask="thirdLevel.shouldMask"
                            >{{ thirdLevel.text }}</Link
                        >
                        <div
                            v-else
                            class="navigation-dropdown__third-level-nav navigation-dropdown__nav"
                            :class="{ 'navigation-dropdown__nav--empty': !thirdLevel.text }"
                        >
                            {{ thirdLevel.text }}
                        </div>
                    </div>

                    <!--Mobile: menu can be visible by default if there is no text to toggle with-->
                    <ul
                        v-if="thirdLevel.children && thirdLevel.children.length"
                        class="list-unstyled navigation-dropdown__deepest-list navigation-dropdown__menu navigation-dropdown__menu-lvl-4"
                    >
                        <li
                            v-for="(fourthLevel, fourthLevelIndex) in thirdLevel.children"
                            :key="'fourthLevel' + fourthLevelIndex"
                            class="navigation-dropdown__fourth-level-item"
                        >
                            <Link
                                :link="fourthLevel.link || {}"
                                :mask="fourthLevel.shouldMask"
                                class="navigation-dropdown__fourth-level-nav navigation-dropdown__nav"
                            >
                                {{ fourthLevel.text }}
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NavigationEntry from '@/models/pre-ts/navigation/NavigationEntry';

export default {
    name: 'NavigationDropdown',
    data() {
        return {
            secondLevelActive: null,
            thirdLevelActive: null
        };
    },

    props: {
        items: {
            type: Array,
            required: true
        }
    },

    computed: {
        maxTwoLevels() {
            return this.items.every(i => {
                const children = i.children || [];
                return !children.length || children.every(c => (c.children || []).length === 0);
            });
        },
        isStartPage() {
            return this.$route.name === 'index';
        }
    },

    methods: {
        isDeepestList(items) {
            return items.every(i => {
                return !(i.children || []).length;
            });
        }
    }
};
</script>

<style scoped lang="scss">
.navigation-dropdown {
    $horizontal-padding: 6px;
    $item-width: 150px;
    padding: 0 10px;

    &:not(&__deepest-list) {
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 10px 10px 0;
    }

    &__link-wrapper {
        display: flex;
        align-items: center;
    }

    &__second-level-nav {
        font-size: 18px;
        font-weight: bold;
        padding: 0 $horizontal-padding;
        display: block;
    }

    &__horizontal {
        display: flex;
        flex-wrap: nowrap;
    }

    &__vertical {
        display: flex;
        flex-direction: column;
    }

    &__third-level-nav {
        display: block;
        font-size: 16px;
        font-weight: bold;
        padding: 0 $horizontal-padding;
        min-width: $item-width;
        min-height: 24px;
    }

    &__deepest-list &__third-level-nav {
        min-height: auto; //preserve third-level missing header space
    }

    &__two-levels-list {
        display: inline-grid !important;
        grid-auto-flow: row;
        grid-template-columns: repeat(5, auto);
    }

    $self: &;
    &__deepest-list {
        display: inline-grid; //for the t
        grid-auto-flow: column;
        grid-template-rows: repeat(5, auto);

        #{$self}__nav {
            width: $item-width;
            padding: 2px $horizontal-padding;
            display: block;
            text-decoration: none;
            font-weight: normal;
            font-size: 14px;
            color: var(--body-color);

            &--empty {
                padding: 0;
            }

            &:hover,
            &.nuxt-link-exact-active {
                background-color: var(--border-color);
            }
        }
    }
}
</style>
