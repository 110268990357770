export default function cloneDeep(val, instanceClone) {
    if (Array.isArray(val)) {
        return cloneArrayDeep(val, instanceClone);
    } else if (val === null) {
        //typeof null === 'object'
        return val;
    } else if (typeof val === 'object' || typeof val === 'function') {
        // object, function, error
        return cloneObjectDeep(val, instanceClone);
    }
    return val;
}

function cloneObjectDeep(val, instanceClone) {
    if (typeof instanceClone === 'function') {
        return instanceClone(val);
    }
    if (instanceClone || isPlainObject(val)) {
        const res = new val.constructor();
        for (let key in val) {
            res[key] = cloneDeep(val[key], instanceClone);
        }
        return res;
    }
    return val;
}

function cloneArrayDeep(val, instanceClone) {
    const res = new val.constructor(val.length);
    for (let i = 0; i < val.length; i++) {
        res[i] = cloneDeep(val[i], instanceClone);
    }
    return res;
}

function isPlainObject(o) {
    var ctor, prot;

    if (isObject(o) === false) return false;

    // If has modified constructor
    ctor = o.constructor;
    if (ctor === undefined) return true;

    // If has modified prototype
    prot = ctor.prototype;
    if (isObject(prot) === false) return false;

    // If constructor does not have an Object-specific method
    if (prot.hasOwnProperty('isPrototypeOf') === false) {
        return false;
    }

    // Most likely a plain Object
    return true;
}

function isObject(o) {
    return Object.prototype.toString.call(o) === '[object Object]';
}
