export default {
    prepayment: {
        text: 'PaymentMethod.prepayment',
        value: 'Prepayment',
        iconSrc: 'paymentMethods/vorkasse.svg'
    },
    sofortUeberweisung: {
        text: 'PaymentMethod.sofort',
        value: 'SofortUeberweisung',
        iconSrc: 'paymentMethods/sofortueberweisung.svg'
    },
    payPal: {
        text: 'PaymentMethod.payPal',
        value: 'PayPal',
        iconSrc: 'paymentMethods/payPal.svg'
    },
    payPalPlus: {
        text: 'PaymentMethod.payPalPlus', //not set
        value: 'PayPalPlus',
        iconSrc: ['paymentMethods/payPal.svg', 'paymentMethods/visa.svg', 'paymentMethods/mastercard.svg']
    },
    paydirekt: {
        text: 'PaymentMethod.payDirect',
        value: 'Paydirekt',
        iconSrc: 'paymentMethods/giropay.svg'
    },
    amazonPay: {
        text: 'PaymentMethod.amazonPay',
        value: 'AmazonPay',
        iconSrc: 'paymentMethods/amazonPay.svg'
    },
    stripe: {
        text: 'PaymentMethod.stripe',
        value: 'Stripe'
    },
    applePay: {
        text: 'PaymentMethod.applePay',
        value: 'ApplePay',
        iconSrc: 'paymentMethods/applePay.svg'
    },
    googlePay: {
        text: 'PaymentMethod.googlePay',
        value: 'GooglePay',
        iconSrc: 'paymentMethods/googlePay.svg'
    }
};
