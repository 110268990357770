/**
 * @typedef {"left"|"center"|"right"} Alignment
 */

/**
 * @class
 */
export default class NavigationEntry {
    /**
     * @constructor
     * @param { string} text
     * @param {Alignment} [alignment]
     * @param {Link} [link]
     * @param {string} [menuHtmlCode]
     * @param {string} [mobileMenuHtmlCode]
     * @param {NavigationEntry} [children]
     * @param {string} [icon] - artificially added
     */
    constructor({ text, alignment, link, menuHtmlCode, mobileMenuHtmlCode, children, icon }) {
        this.text = text;
        this.alignment = alignment;
        this.link = link;
        this.menuHtmlCode = menuHtmlCode;
        this.mobileMenuHtmlCode = mobileMenuHtmlCode;
        this.children = children;
        this.icon = icon;
    }
}
