<template>
    <nav id="navigation" ref="navbar" class="navbar navbar-expand-md container d-print-none">
        <div id="navbarNav">
            <NavigationItems v-if="navigationItemsWithMasking" :entries="navigationItemsWithMasking" />
        </div>
    </nav>
</template>

<script>
import NavigationItems from '@/components/navigation/NavigationItems';
import navigationItemsMasking from '@/mixins/navigationItemsMasking';

export default {
    name: 'Navigation',
    components: {
        NavigationItems
    },
    mixins: [navigationItemsMasking]
};
</script>

<style scoped lang="scss">
@use '@/styles/theming' as *;

#navigation {
    margin: 0 auto;
    border-top: 1px solid hsl(0, 0%, 92%);
    padding: 0;
    min-height: 38px; //serves as fallback height for skeleton
}
</style>
