<template>
    <div class="kiu-payment-methods">
        <icon-svg v-for="paymentMethod in paymentMethodsByValue" :key="paymentMethod" :filepath="paymentMethod" />
    </div>
</template>

<script>
import paymentMethods from '@/models/paymentMethods';

export default {
    name: 'KIUFooterPaymentMethods',
    computed: {
        availablePaymentMethods() {
            return this.$store.state.globalMeta.availablePaymentMethods;
        },

        paymentMethodsByValue() {
            const icons = [];
            const paymentMethodValues = Object.values(paymentMethods);

            this.availablePaymentMethods.forEach(pm => {
                const found = paymentMethodValues.find(m => m.value === pm);

                // Note: was afraid to change paymentMethods object in order to prevent main shop side effects
                // Should Stripe always mean Visa and MC = we need to refactor in accordance
                if (pm === 'Stripe') {
                    found.iconSrc = ['paymentMethods/visa.svg', 'paymentMethods/mastercard.svg'];
                }

                if (found && found.iconSrc) {
                    icons.push(found.iconSrc);
                }
            });

            const uniqueIcons = new Set(icons.flat());

            return Array.from(uniqueIcons);
        }
    }
};
</script>

<style scoped lang="scss">
.kiu-payment-methods {
    display: flex;
    font-size: 2.5rem;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.625rem;
}
</style>
