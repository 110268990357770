<template>
    <div
        id="floatingBottom"
        class="floating-bottom container-fluid"
        :class="{ 'floating-bottom--expanded': expanded }"
        ref="bottomFloat"
    >
        <div class="row">
            <div
                class="floating-bottom__components col-auto col-sm-3 order-1 order-sm-first mr-auto"
                :ref="alignments.left"
            ></div>
            <div
                class="floating-bottom__components floating-bottom__components--center col-12 col-sm-6 order-first order-sm-1 align-items-center"
                :ref="alignments.center"
            >
                <transition-group tag="div" name="list" class="floating-bottom__snackbars order-last">
                    <Snackbar
                        v-for="(message, index) in messages"
                        :message="message"
                        :key="'snackbar' + index"
                        @close="close(index)"
                        class="floating-bottom__child floating-bottom__snackbar"
                    />
                </transition-group>
            </div>
            <div
                class="floating-bottom__components col-auto col-sm-3 order-2 order-sm-last align-items-end ml-auto"
                :ref="alignments.right"
            ></div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import Snackbar from '@/components/layout/Snackbar';

export default {
    name: 'FloatingBottom',
    components: {
        Snackbar
    },
    data() {
        return {
            mutationObserver: null,
            resizeObserver: null,
            messages: [],
            timeoutId: null,
            alignments: {
                left: 'left',
                center: 'center',
                right: 'right'
            },
            expanded: false //expands when appended fixed child gets expanded to percentage (chat button => chat window)
        };
    },
    computed: {},
    methods: {
        /**
         *
         * @param {Node | #text} elementOrText
         * @param {"left"|"center"|"right"|number|null} alignmentOrTimeout
         */
        addBottomFloat(elementOrText, alignmentOrTimeout) {
            if (elementOrText instanceof Element) {
                this.showElement(elementOrText, alignmentOrTimeout || this.alignments.right);
            } else {
                this.showSnackbar(elementOrText, alignmentOrTimeout);
            }
        },
        removeBottomFloat(elementOrText) {
            if (elementOrText instanceof Element) {
                //prefer Element.prototype.remove over calling this this.removeBottomFloat
                for (let alignment in this.alignments) {
                    if (this.$refs[alignment].contains(elementOrText)) {
                        this.$refs[alignment].removeChild(elementOrText);
                    }
                }
            } else {
                const index = this.messages.indexOf(elementOrText);
                ~index && this.messages.splice(index, 1);
            }
        },
        showElement(element, alignment) {
            //marking appended fixed child for possible future dimensions updates to observe
            (element.style.position === 'fixed' || !this.isVueComponent(element)) &&
                (element.dataset.observeResize = true);
            this.$refs[alignment].append(element);
        },
        showSnackbar(message, timeout = 3000) {
            if (this.$isDev) {
                console.assert(!!message, 'Snackbar message should not be empty');
            }

            let length = this.messages.length;
            const duplicate = ~this.messages.indexOf(message);
            if (!duplicate) {
                length = this.messages.push(message);
            } else {
                clearTimeout(this.timeoutId);
            }

            if (timeout != null) {
                this.timeoutId = setTimeout(() => {
                    this.close(length - 1);
                }, timeout);
            }
        },
        callback(mutationList, observer) {
            mutationList.forEach(mutation => {
                if (mutation.type === 'childList') {
                    Array.prototype.forEach.call(mutation.addedNodes, node => {
                        this.distributeToAlignment(node);

                        if (node.dataset.observeResize) {
                            if (!this.resizeObserver) {
                                this.resizeObserver = new ResizeObserver(this.onComponentResize);
                            }
                            this.resizeObserver.observe(node);
                        }
                    });
                }
            });
        },
        onComponentResize(entries) {
            for (let { target } of entries) {
                const { width, height } = target.style;

                const fullWidth = width.includes('%');
                const fullHeight = height.includes('%');

                if (fullWidth || width.includes('vh') || fullHeight || height.includes('vh')) {
                    target.classList.add('fixed');
                    if (fullWidth) {
                        target.classList.add('fixed--full-width');
                    }
                    if (fullHeight) {
                        target.classList.add('fixed--full-height');
                    }
                    this.expanded = true;
                } else {
                    target.classList.remove('fixed', 'fixed--full-width', 'fixed--full-height');
                    this.expanded = false;
                }
            }
        },
        distributeToAlignment(node) {
            //cannot use window.getComputedStyle since it returns "logical" left instead of an explicitly set
            this.addBottomFloat(
                node,
                node.style.left && node.style.left !== 'auto' ? this.alignments.left : this.alignments.right
            );
        },
        close(index) {
            this.messages.splice(index, 1);
        },
        isVueComponent(element) {
            //or is it non-third-party
            return !!element.__vue__; //TODO: vue3 migration
        }
    },
    created() {
        //first attempt to add global instance props after Vue instantiation.
        Vue.prototype.$addBottomFloat = this.addBottomFloat;
        Vue.prototype.$removeBottomFloat = this.removeBottomFloat;

        if (process.client) {
        }
    },
    mounted() {
        this.mutationObserver = new MutationObserver(this.callback);
        this.mutationObserver.observe(this.$refs.bottomFloat, {
            childList: true,
            attributes: false,
            subtree: false
        });

        if (
            this.$nuxt.iframeLayout &&
            typeof this.$nuxt.iframeLayout.isInsideIframe === 'function' &&
            this.$nuxt.iframeLayout.isInsideIframe()
        ) {
            this.$nuxt.iframeLayout.fixPositionInIframe(this.$el, -60);
        }
    },
    beforeDestroy() {
        this.mutationObserver && this.mutationObserver.disconnect();
        this.resizeObserver && this.resizeObserver.disconnect();
    }
};
</script>

<style lang="scss">
.floating-bottom__components > *:not(.floating-bottom__snackbars) {
    //forcing children to position themselves correctly
    position: relative !important;
    margin-bottom: 10px !important;
}

.floating-bottom__components > .fixed {
    position: fixed !important;

    &--full-width {
        left: 0;
        right: 0;
    }

    &--full-height {
        top: 0;
        bottom: 0;
    }
}
</style>

<style scoped lang="scss">
@use 'sass:map';
@use '@/styles/variables';

.floating-bottom {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    z-index: map.get(variables.$z-indices, 'bottom-float');

    &--expanded {
        bottom: 0 !important;
    }
}

.floating-bottom__components {
    display: flex;
    flex-direction: column-reverse;

    &--center {
        z-index: 1;
    }
}

.floating-bottom__snackbars {
    display: flex;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    z-index: map.get(variables.$z-indices, 'tooltips');
}

.list-enter-active,
.list-leave-active {
    transition: all 0.3s;
}

.list-enter {
    transform: translateY(100%);
}

.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
    opacity: 0;
}

.list-leave-to {
    transform: translateY(-100%);
}
</style>
