<template>
    <div class="footer-wrapper">
        <footer id="footer" class="container">
            <div class="footer__sections row">
                <section class="col-sm">
                    <nuxt-link :to="{ name: 'category', params: { category: termsCategory } }" target="_blank">
                        {{ $t('CheckoutFooter.agb') }}
                    </nuxt-link>
                </section>
                <section class="col-sm">
                    <nuxt-link :to="{ name: 'category', params: { category: privacyCategory } }" target="_blank">
                        {{ $t('CheckoutFooter.privacyProtection') }}
                    </nuxt-link>
                </section>
                <section class="col-sm">
                    <nuxt-link :to="{ name: 'category', params: { category: imprintCategory } }" target="_blank">
                        {{ $t('CheckoutFooter.imprint') }}
                    </nuxt-link>
                </section>
            </div>
            <FooterLegalInfo />
        </footer>
    </div>
</template>

<script>
import FooterLegalInfo from '@/components/FooterLegalInfo';
export default {
    name: 'CheckoutFooter',
    components: { FooterLegalInfo },
    computed: {
        theme() {
            return this.$store.state.theme;
        },
        termsCategory() {
            return this.$t('CheckoutFooter.agb.url') || 'agb';
        },
        privacyCategory() {
            return this.$t('CheckoutFooter.privacyProtection.url') || 'datenschutz';
        },
        imprintCategory() {
            return this.$t('CheckoutFooter.imprint.url') || 'impressum';
        }
    }
};
</script>

<style scoped lang="scss">
@use '@/styles/variables';
@use '@/styles/theming' as *;

footer#footer {
    background-color: var(--footer-bg-color);
    color: var(--footer-header-color);

    section a {
        color: var(--footer-link-color);
    }

    padding: variables.$grid-gutter-width;
    margin-top: variables.$grid-gutter-width;

    section {
        text-align: center;

        a {
            font-size: 16px;
            text-decoration: none;
        }
    }
}
</style>
