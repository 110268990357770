<template>
    <!-- anchor instead of a router-link in order to make Logo reusable in non-routing projects -->
    <component :is="component" v-bind="hrefAttr" class="logo text-decoration-none">
        <h5 v-if="cooperationText" class="cooperation-text">
            {{ $t('Logo.inCooperation') }}
        </h5>

        <div class="logo-wrapper">
            <img v-if="src" :src="src" alt="Partner Logo" class="logo-image img-fluid" />
            <IconSvg v-else filepath="animod-logo.svg" class="logo-image img-fluid" />
        </div>
    </component>
</template>

<script>
export default {
    name: 'Logo',
    props: {
        src: String,
        href: String,
        cooperationText: Boolean
    },
    computed: {
        useRouter() {
            return this.$store.getters.isMainShop && !(this.href || '').startsWith('http');
        },
        component() {
            if (this.useRouter) {
                return 'nuxt-link';
            }
            return 'a';
        },
        hrefAttr() {
            if (this.useRouter) {
                // intentional absence of absolute url check: if using router only relative urls should be in place
                return { to: this.href || '/' };
            }
            return { href: this.href || '/' };
        }
    }
};
</script>

<style scoped lang="scss">
@use '@/styles/variables';

.logo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    color: inherit;

    .cooperation-text {
        font-size: 12px;
        text-align: center;
        margin-bottom: 5px;
        flex-shrink: 0;
    }

    .logo-wrapper {
        flex: 1;
        display: flex;
        align-items: center;
        overflow: hidden;

        .logo-image {
            max-width: 100%;
            max-height: 100%;

            @include variables.ie {
                width: 100%;
            }
        }
    }
}
</style>
