<template>
    <CustomerService class="kiu-customer-service">
        <template #header>
            <header class="kiu-customer-service__header">Fragen oder Wunschtermin?</header>
        </template>
    </CustomerService>
</template>

<script>
import CustomerService from '@/components/header/CustomerService.vue';

export default {
    name: 'KIUCustomerService',
    components: { CustomerService }
};
</script>

<style scoped lang="scss">
.kiu-customer-service {
    line-height: 1.25;

    &__header {
        font-size: 0.875rem;
    }

    ::v-deep {
        .phone {
            font-weight: bold;
            font-size: 1.5rem;
        }
    }
}
</style>
