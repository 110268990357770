<template>
    <div v-html="html" ref="htmlContainer" class="nav-item-html"></div>
</template>

<script>
export default {
    name: 'NavigationItemHtml',
    props: {
        html: {
            type: String,
            required: true
        }
    },
    methods: {
        maskHtmlLinks() {
            const linksToMask = this.$refs.htmlContainer.querySelectorAll('.menu-block a:not(.link-masked)');
            linksToMask.forEach(anchor => {
                //not replacing to span to keep styling
                const href = anchor.getAttribute('href');
                anchor.setAttribute('href', '#');
                anchor.addEventListener('click', event => {
                    event.preventDefault();
                    window.open(href, '_self');
                });
            });
        }
    },
    mounted() {
        this.maskHtmlLinks();
    }
};
</script>

<style scoped lang="scss">
@use '@/styles/mixins';
@use '@/styles/variables';
$dropdown-padding: 10px;

.nav-item-html ::v-deep {
    font-size: 14px;

    .menu-block {
        padding: 0 $dropdown-padding;
    }

    ul {
        list-style-type: none;
        padding: 0;
    }

    .sub-heading,
    .subheading {
        font-size: 16px;
        font-weight: bold;
        padding: 0 6px;
    }

    a {
        color: var(--body-color);
        padding: 2px 6px;
        display: block;
        text-decoration: none;

        &:hover,
        &.nuxt-link-exact-active {
            background-color: var(--border-color);
        }
    }

    .heading {
        font-size: 18px;
        font-weight: bold;
        padding: variables.$grid-padding $dropdown-padding 0;

        a {
            display: inline-block;
        }
    }

    .menu-group {
        display: table;

        .menu-block {
            display: table-cell;
        }
    }

    .btn-icon {
        font-size: 20px;
        margin-left: auto;
        padding-left: 5px;
        border-left: 1px solid variables.$border-color;

        .icon-svg {
            transition: transform 0.1s ease-in-out;
        }
    }

    @include mixins.mobile-only {
        .menu-group,
        .menu-block {
            width: auto !important;
            display: block !important;
        }

        .heading,
        .subheading,
        .sub-heading,
        a {
            color: var(--mobile-menu-color);
        }
    }
}
</style>
