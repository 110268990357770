<template>
    <ul class="nav-mobile-menu__list list-unstyled">
        <NavigationMenuMobileListItem
            v-for="(item, index) in items"
            :key="'item' + index"
            :item="item"
            @openSubMenu="openSubMenu(item)"
            @navClick="navClick(item)"
        />
    </ul>
</template>

<script>
import NavigationMenuMobileListItem from '@/components/navigation/NavigationMenuMobileListItem';

export default {
    name: 'NavigationMenuMobileList',

    components: { NavigationMenuMobileListItem },

    data() {
        return {};
    },

    props: {
        items: {
            type: Array,
            required: true
        }
    },

    methods: {
        openSubMenu(item) {
            this.$emit('openSubMenu', item);
        },
        navClick(item) {
            this.$emit('navClick', item);
        }
    }
};
</script>

<style scoped lang="scss">
.nav-mobile-menu__list {
    &--footer {
        padding-top: 5px;
        padding-bottom: 5px;
        margin-bottom: 0;
    }
}
</style>
