<template>
    <Autocomplete :value.sync="query" placeholder="Wohin soll es gehen?" submit-on-desktop class="kiu-autocomplete">
        <template #default><span></span></template>

        <template #append>
            <media-only desktop>
                <button type="button" class="kiu-autocomplete__button-desktop btn btn-gray" @click="navigateToSearch">
                    <icon-svg filepath="search/magnifier.svg" />
                </button>
            </media-only>
        </template>
    </Autocomplete>
</template>

<script>
import Autocomplete from '@/components/search/Autocomplete.vue';

export default {
    name: 'KIUSearchAutocomplete',
    components: { Autocomplete },

    data() {
        return {
            query: ''
        };
    },

    methods: {
        navigateToSearch() {
            this.$router.push({
                name: 'searchResult',
                query: {
                    q: this.query
                }
            });
        }
    }
};
</script>

<style scoped lang="scss">
@use '@/themes/kurzinurlaub/styles/variables';
@use '@/styles/mixins';

.kiu-autocomplete {
    flex: 1;

    ::v-deep {
        .input-wrapper {
            height: 40px;

            .input-gray {
                border: 1px solid var(--border-color);
                padding-inline: 0.625rem;
                height: 100%;
            }

            &:has(.input-gray:focus) {
                box-shadow: 0 0 3px 1px hsl(208, 66%, 64%);
            }

            @include mixins.mobile-only {
                height: 2rem;
            }
        }

        .dropdown-menu {
            border-radius: 0;
        }
    }

    &__button-desktop,
    &__button-desktop:hover,
    &__button-desktop:focus-visible {
        background-color: variables.$gray;
        color: hsl(0, 0%, 100%);
    }

    &__button-desktop {
        height: 40px;
    }
}
</style>
